import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { environment } from '../../../../environments/environment';
import { ApiRequestService } from '../../../core/api/apiRequest.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

@Component({
    selector: 'app-show-software-modal',
    templateUrl: './showSoftwareModal.component.html',
    styleUrls: ['./showSoftwareModal.component.scss']
})
export class ShowSoftwareModalComponent implements OnInit {

    private title: string;
    private user;
    private software;
    private messages;
    private form: FormGroup;
    private date: Date;

    constructor(
      private fb: FormBuilder,
      private dialogRef: MatDialogRef<ShowSoftwareModalComponent>,
      private _requestService: ApiRequestService,
      @Inject(MAT_DIALOG_DATA) data) {

        this.title = data.title;
        if(data.obj) {
            this.software = data.obj;
        }
        else {
            this.getSoftware(data.id);
        }
    }

    ngOnInit() {

      this.form = this.fb.group({
        message: [],
      });
    }

    close() {
      this.dialogRef.close();
    }

    /*************************** Request API functions ******************************/

    private getSoftware(softwareId) {
      this._requestService.getObject('Softwares', { 'objectId': softwareId }).subscribe(
        data => {
          this.software = data;
        }
      );
    }

    private getHardwareSoftware(softwareId) {
      this._requestService.getObject('Hardwares', { 'objectId': softwareId + '/softwares' }).subscribe(
        data => {
          this.messages = data;
        }
      );
    }

}
