import { Injectable } from '@angular/core';
import {
  HttpInterceptor, HttpRequest, HttpHandler, HttpEvent,
  HttpSentEvent, HttpHeaderResponse, HttpProgressEvent,
  HttpResponse, HttpUserEvent, HttpErrorResponse
  } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router, RouterStateSnapshot } from '@angular/router';
import { TokenStorage, UserStorage } from './core/_storage';
import { AuthService } from './core/_services/auth.service';
import 'rxjs/add/operator/do';

const TOKEN_HEADER_KEY = 'Authorization';

@Injectable()
export class Interceptor implements HttpInterceptor {

  constructor(private token: TokenStorage, private user: UserStorage, private auth: AuthService, private router: Router) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let authReq = req;
    if (this.token.getToken() != null) {
      authReq = req.clone({ headers: req.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + this.token.getToken())});
      // console.log('Debug:: Intercept Token OK');
    }
    return next.handle(authReq).do(
      () => {},
      (err: any) => {
        if (err instanceof HttpErrorResponse) {
          console.log('Debug:: Error handled '+ err.status + '\n' + err.message);
         
          if (err.status === 401) {
            console.log('Debug:: '+err.error.message);
            if (err.error.message == "Expired JWT Token"){
              // Try to renew Token
              this.auth.renewToken(this.token.getRenewToken()).subscribe(
                data => {
                  this.token.saveToken(data['token']);
                },
                err => {
                  console.log("Error:: Renew token error");
                  console.log(err);

                  // this.router.navigate(['/session/signin']);
                  // TODO Refresh token
                  // this.user.signOut();
                }
              )
            }
            else {
              console.log("Erorr:: no renew token available");
              this.router.navigate(['/session/signin']);
            }
          }
        }
      }
    );
  }

}
