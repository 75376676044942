import { Component, OnInit, Inject, Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';  // Angular 5/RxJS 5.5 syntax
// import {forkJoin} from 'rxjs';  // change to new RxJS 6 import syntax

import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { environment } from '../../../../environments/environment';

import { ApiRequestService } from '../../../core/api/apiRequest.service';

@Component({
    selector: 'app-user-edit-form-validation',
    templateUrl: './userFormModal.component.html',
    styleUrls: ['./userFormModal.component.scss']
})
export class UserFormModalComponent implements OnInit {

    private form: FormGroup;
    private user;
    private roles;
    private clients;
    private isNew = true;
    private pwdChange = false;
    private env = environment.formValidation;

    private plainPassword = new FormControl(null, Validators.compose([Validators.required, Validators.minLength(environment.formValidation.password.minLength)]));
    private confirmPassword = new FormControl(null, CustomValidators.equalTo(this.plainPassword));

    title: string;

    constructor(
        private fb: FormBuilder,
        private dialogRef: MatDialogRef<UserFormModalComponent>,
        private http:HttpClient,
        private _requestService: ApiRequestService,
        @Inject(MAT_DIALOG_DATA) data) {

            this.title = data.title;

            if (data.user_id) {
                this.isNew = false;
                this.getUser(data.user_id);
            }
            this.getRoles();
            this.getClients();
    }

    getUser(user_id) {
        this._requestService.getObject('Users', {'objectId': user_id}).subscribe(
            data => { this.user = data },
            err => console.error(err),
            () => console.log('Debug:: loading user')
        );
    }

    getRoles() {
        this._requestService.getList('Roles').subscribe(
            data => { this.roles = data },
            err => console.error(err),
            () => console.log('Debug:: loading roles')
        );
    }

    getClients() {
        this._requestService.getList('Clients').subscribe(
            data => { this.clients = data },
            err => console.error(err),
            () => console.log('Debug:: loading clients')
        );
    }

    ngOnInit() {
        if (this.pwdChange || this.isNew) {
            this.form = this.fb.group({
                firstName: [null, Validators.compose([
                    Validators.required, 
                    Validators.minLength(environment.formValidation.firstName.minLength), 
                    Validators.maxLength(environment.formValidation.firstName.maxLength)]
                )],
                lastName: [null, Validators.compose([
                    Validators.required, 
                    Validators.minLength(environment.formValidation.lastName.minLength), 
                    Validators.maxLength(environment.formValidation.lastName.maxLength)]
                )],
                email: [null, Validators.compose([Validators.required, CustomValidators.email])],
                role: [null, Validators.compose([Validators.required])],
                phoneNumber: [null, Validators.compose([Validators.required, CustomValidators.phone('FR')])],
                plainPassword: this.plainPassword,
                confirmPassword: this.confirmPassword,
                pwdCheckbox: [],
                company: [null, Validators.compose([Validators.required])],
            });
        }
        else {
            this.form = this.fb.group({
                firstName: [null, Validators.compose([
                    Validators.required, 
                    Validators.minLength(environment.formValidation.firstName.minLength), 
                    Validators.maxLength(environment.formValidation.firstName.maxLength)]
                )],
                lastName: [null, Validators.compose([
                    Validators.required, 
                    Validators.minLength(environment.formValidation.lastName.minLength), 
                    Validators.maxLength(environment.formValidation.lastName.maxLength)]
                )],
                email: [null, Validators.compose([Validators.required, CustomValidators.email])],
                role: [null, Validators.compose([Validators.required])],
                phoneNumber: [null, Validators.compose([Validators.required, CustomValidators.phone('FR')])],
                plainPassword: [null, Validators.minLength(environment.formValidation.password.minLength)],
                confirmPassword: this.confirmPassword,
                pwdCheckbox: [],
                company: [null, Validators.compose([Validators.required])],
            });
        }
    }

    save() {
        var ret;
        if (this.isNew) {
            if (this.form.value) {
                ret = this.createUser(this.form.value);
            }
        }
        else {
            if (this.form.value && this.user) {
                ret = this.updateUser(this.user.id, this.form.value);
            }
        }
        this.dialogRef.close(ret);
        // this.dialogRef.afterClosed();
    }

    createUser(user) {
        this._requestService.postObject('Users', { 'object': user }).subscribe(
            data => {
                return data;
            },
            error => {
                console.error("Error saving user...");
                return Observable.throw(error);
            }
        );
    }
  
    updateUser(user_id, user) {
        this._requestService.patchObject('Users', {'objectId':user_id, 'object':user}).subscribe(
            data => {
                return data;
            },
            error => {
                console.error("Error update user...");
                return Observable.throw(error);
            }
        );
    }

    upgradeUser(user_id, user) {
        this._requestService.putObject('Users', {'objectId':user_id, 'object':user}).subscribe(
            data => {
                return data;
            },
            error => {
                console.error("Error upgrade user...");
                return Observable.throw(error);
            }
        );
    }

    close() {
        this.dialogRef.close();
    }

    compareObjects(o1: any, o2: any): boolean {
        if (o1 !== null && o2 !== null) {
            return o1.id === o2.id;
        }
        return false;
    }
}
