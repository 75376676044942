import { Component, OnInit, Inject, Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';  // Angular 5/RxJS 5.5 syntax
// import {forkJoin} from 'rxjs';  // change to new RxJS 6 import syntax

import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { environment } from '../../../../environments/environment';

import { ApiRequestService } from '../../../core/api/apiRequest.service';

const plainPassword = new FormControl('', Validators.compose([Validators.required, Validators.minLength(environment.formValidation.password.minLength)]));
const confirmPassword = new FormControl('', CustomValidators.equalTo(plainPassword));

@Component({
    selector: 'app-client-edit-form-validation',
    templateUrl: './clientFormModal.component.html',
    styleUrls: ['./clientFormModal.component.scss']
})
export class ClientFormModalComponent implements OnInit {

    private form: FormGroup;
    private client;
    private users;
    private isNew = true;
    private pwdChange = false;

    title: string;

    constructor(
        private fb: FormBuilder,
        private dialogRef: MatDialogRef<ClientFormModalComponent>,
        private http:HttpClient,
        private _requestService: ApiRequestService,
        @Inject(MAT_DIALOG_DATA) data) {

            this.title = data.title;

            if (data.id) {
                this.isNew = false;
                this.getClient(data.id);
            }
            this.getUsers();
    }

    getClient(clientId) {
        this._requestService.getObject('Clients', { 'objectId': clientId }).subscribe(
            data => { this.client = data },
            err => console.error(err),
            () => console.log('Debug:: loading clients')
        );
    }

    getUsers() {
        this._requestService.getList('Users').subscribe(
            data => { this.users = data },
            err => console.error(err),
            () => console.log('Debug:: loading users')
        );
    }

    ngOnInit() {
        this.form = this.fb.group({
            name: [null, Validators.compose([
                Validators.required]
            )],
            phone: [],
            addresse: [],
            postCode: [],
            city: [],
            defaultAgent: []
        });
    }

    close() {
        this.dialogRef.close(false);
    }

    save() {
        this.dialogRef.close(this.form.value);
        if (this.isNew) {
            if (this.form.value) {
                this.createClient(this.form.value);
            }
        }
        if (this.form.value && this.client) {
            this.updateClient(this.client.id, this.form.value);
        }
    }

    createClient(client) {
        this._requestService.postObject('Clients', { 'object': client }).subscribe(
            data => {
                return true;
            },
            error => {
                console.error("Error saving client...");
                return Observable.throw(error);
            }
        );
    }
  
    updateClient(clientId, client) {
        this._requestService.patchObject('Clients', {'objectId':clientId, 'object':client}).subscribe(
            data => {
                return true;
            },
            error => {
                console.error("Error update client...");
                return Observable.throw(error);
            }
        );
    }

    upgradeClient(clientId, client) {
        this._requestService.putObject('Clients', {'objectId':clientId, 'object':client}).subscribe(
            data => {
                return true;
            },
            error => {
                console.error("Error upgrade client...");
                return Observable.throw(error);
            }
        );
    }

    compareObjects(o1: any, o2: any): boolean {
        if (o1 !== null && o2 !== null) {
            return o1.id === o2.id;
        }
        return false;
    }
}
