import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { environment } from '../../../../environments/environment';
import { ApiRequestService } from '../../../core/api/apiRequest.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

@Component({
    selector: 'app-show-hardware-modal',
    templateUrl: './showHardwareModal.component.html',
    styleUrls: ['./showHardwareModal.component.scss']
})
export class ShowHardwareModalComponent implements OnInit {

    private title: string;
    private user;
    private hardware;
    private messages;
    private form: FormGroup;
    private date: Date;

    constructor(
      private fb: FormBuilder,
      private dialogRef: MatDialogRef<ShowHardwareModalComponent>,
      private _requestService: ApiRequestService,
      @Inject(MAT_DIALOG_DATA) data) {

        this.title = data.title;
        if(data.obj) {
            this.hardware = data.obj;
        }
        else {
            this.getHardware(data.id);
        }
    }

    ngOnInit() {

      this.form = this.fb.group({
        message: [],
      });
    }

    close() {
      this.dialogRef.close();
    }

    /*************************** Request API functions ******************************/

    private getHardware(hardwareId) {
      this._requestService.getObject('Hardwares', { 'objectId': hardwareId }).subscribe(
        data => {
          this.hardware = data;
        }
      );
    }

    private getHardwareSoftware(hardwareId) {
      this._requestService.getObject('Hardwares', { 'objectId': hardwareId + '/softwares' }).subscribe(
        data => {
          this.messages = data;
        }
      );
    }

}
