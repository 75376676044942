import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable()
export class AuthService {

  private loginPath  = '/login_check';
  private renewPath = '/token/refresh';

  constructor(private http: HttpClient) {
  }

  attemptAuth(ussername: string, password: string) {
    const credentials = {username: ussername, password: password};
    console.log('Debug:: Attenp Auth');
    return this.http.post(environment.apiUrl+this.loginPath, credentials);
  }

  renewToken(renewToken: string) {
    // const credentials = {username: ussername, password: password};
    console.log('Debug:: Renew Token');
    const renew = {"refresh_token": renewToken};
    return this.http.post(environment.apiUrl+this.renewPath, renew);
  }

}
