import { Injectable } from '@angular/core';
import { User, Role } from '../_models/';

const TOKEN_KEY = 'UserData';

@Injectable()
export class UserStorage {

  constructor() { }

  signOut() {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.clear();
  }

  public saveUser(user: User) {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY,  JSON.stringify(user));
  }

  public getUser(): User {
    return JSON.parse(sessionStorage.getItem(TOKEN_KEY));
  }
}