// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  formValidation: {
    firstName: {
      minLength: 2,
      maxLength: 20
    },
    lastName: {
      minLength: 2,
      maxLength: 20
    },
    password: {
      minLength: 6,
      specialChar: true,
      lowerCase: true,
      upperCase: true,
      numbers: true
    }
  },
  production: true,
  // apiUrl: 'http://localhost/web/TimeTrackerAPI/public/api',
  // apiUrl: 'http://192.168.1.4/web/TimeTrackerAPI/public/api',
  // apiUrl: 'http://192.168.4.29/web/TimeTrackerAPI/public/api',
  // apiUrl: 'http://192.168.43.189/web/TimeTrackerAPI/public/api',
  apiUrl: 'https://sith.iconet.fr/api',
  apiFunctionPath: {
    'Hardwares': '/inventories/hardwares',
    'HardwareModels': '/inventories/hardwareModels',
    'HardwareStatus': '/inventories/hardwareStatus',
    'HardwareTypes':  '/inventories/hardwareTypes',
    'Softwares': '/inventories/softwares',
    'SoftwareModels': '/inventories/softwareModels',
    'SoftwareStatus': '/inventories/softwareStatus',
    'SoftwareTypes':  '/inventories/softwareTypes',
    'SoftwareLicenceTypes':  '/inventories/softwareLicenceTypes',
    'OperatingSystems': '/inventories/operatingSystems',
    'Manufacturers': '/inventories/manufacturers',
    'Editors': '/inventories/editors',
    'Users': '/users',
    'Roles': '/roles',
    'Tickets': '/tickets',
    'TicketMessages': '/ticketMessages',
    'TicketPriorities': '/ticketPriorities',
    'TicketStatus': '/ticketStatus',
    'Clients': '/clients',
    'ServiceTypes': '/serviceTypes',
    'Register': '/register',
    'Agents': '/usersAgents',
    'Forget': '/forgetPassword',
    'Reset': '/resetPassword'
  }
};
