import { Injectable } from '@angular/core';

const TOKEN_KEY = 'AuthToken';
const RENEW_KEY = 'renewToken';

@Injectable()
export class TokenStorage {

  constructor() { }

  signOut() {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.clear();
  }

  public saveToken(token: string) {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);
  }

  public saveRenewToken(renew: string, rememberMe=false){
    if (rememberMe) {
      window.localStorage.removeItem(RENEW_KEY);
      window.localStorage.setItem(RENEW_KEY, renew);
    }
    else {
      window.sessionStorage.removeItem(RENEW_KEY);
      window.sessionStorage.setItem(RENEW_KEY, renew);
    }
  }

  public getToken(): string {
    return sessionStorage.getItem(TOKEN_KEY);
  }

  public getRenewToken(): string {
    return sessionStorage.getItem(RENEW_KEY);
  }
}