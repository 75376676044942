import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';

import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { environment } from '../../../environments/environment';

const password = new FormControl('', Validators.required);
const confirmPassword = new FormControl('', CustomValidators.equalTo(password));

@Component({
    selector: 'app-delete-dialog',
    templateUrl: './deleteDialog.component.html',
    styleUrls: ['./deleteDialog.component.scss']
})
export class DeleteDialogComponent implements OnInit {

    private title: string;
    private description: string;
    private object = [];
    private objKeys = [];

    constructor(
        private dialogRef: MatDialogRef<DeleteDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data) {

            this.title = data.title;
            this.description = data.description;
            if (data.obj !== undefined) {
                this.object  = data.obj;
                this.objKeys = Object.keys(this.object);
            }
    }


    ngOnInit() {}

    confirm() {
        this.dialogRef.close(true);
    }

    close() {
        this.dialogRef.close(false);
    }
}
