import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
 
import { Alert, AlertType } from '../_models';
import { AlertService } from '../_services/alert.service';
 
@Component({
    // moduleId: module.id,
    selector: 'alert',
    templateUrl: 'alert.component.html'
})
 
export class AlertComponent {
    alerts: Alert[] = [];
 
    constructor(
        public snackBar: MatSnackBar,
        private alertService: AlertService
    ) { }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 2000,
            horizontalPosition: 'center',
            verticalPosition: 'top'
        });
    }
 
    ngOnInit() {
        this.alertService.getAlert().subscribe((alert: Alert) => {
            // if (!alert) {
            //     // clear alerts when an empty alert is received
            //     this.alerts = [];
            //     return;
            // }
 
            // // add alert to array
            // this.alerts.push(alert);
            this.openSnackBar(alert.message, "test");
        });
    }
 
    removeAlert(alert: Alert) {
        this.alerts = this.alerts.filter(x => x !== alert);
    }
 
    cssClass(alert: Alert) {
        if (!alert) {
            return;
        }
 
        // return css class based on alert type
        switch (alert.type) {
            case AlertType.Success:
                return 'alert alert-success';
            case AlertType.Error:
                return 'alert alert-danger';
            case AlertType.Info:
                return 'alert alert-info';
            case AlertType.Warning:
                return 'alert alert-warning';
        }
    }
}