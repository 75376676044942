import { Component, OnInit, Inject, Injectable, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';

import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatDialogConfig } from "@angular/material";
import { environment } from '../../../environments/environment';

import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { QuillEditorComponent } from 'ngx-quill/src/quill-editor.component';
import Quill from 'quill';

import { ApiRequestService } from '../../core/api/apiRequest.service';

import { UserStorage, ClientStorage } from '../../core/_storage/';

import { UserFormModalComponent } from '../../admin/users/userModals/userFormModal.component';
// import { UserShowComponent } from '../../admin/users/userModals/userShow.component';
import { ClientFormModalComponent } from '../../admin/clients/clientModals/clientFormModal.component';


// override p with div tag
const Parchment = Quill.import('parchment');
const Block = Parchment.query('block');

Block.tagName = 'DIV';
// or class NewBlock extends Block {}; NewBlock.tagName = 'DIV';
Quill.register(Block /* or NewBlock */, true);

// Add fonts to whitelist
const Font = Quill.import('formats/font');
Font.whitelist = ['sans-serif', 'monospace', 'serif'];
Quill.register(Font, true);

const dateOpen  = new FormControl(new Date(), Validators.required);
const dateClose = new FormControl('', CustomValidators.gte(dateOpen));

@Component({
    selector: 'app-open-ticket-modal',
    templateUrl: './openTicketModal.component.html',
    styleUrls: ['./openTicketModal.component.scss']
})
export class OpenTicketModalComponent implements OnInit {

    private form: FormGroup;
    private user;
    private isNew = true;
    private pwdChange = false;
    private clients;
    private clientsTemp;
    private clientUsers;
    private clientUsersTemp;
    private agentUsers;
    private agentUsersTemp;
    private serviceTypes;
    private inventories;
    private priorities;
    private ticket;
    private statuses;
    clientValue;
    private displayLabel = "";

    private rightDialogConfig = new MatDialogConfig();

    title: string;

    constructor(
        private fb: FormBuilder,
        private dialogRef: MatDialogRef<OpenTicketModalComponent>,
        private http:HttpClient,
        private _requestService: ApiRequestService,
        private _userStorage: UserStorage,
        private _clientStorage: ClientStorage,
        private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) data) {

            this.title = data.title;

            if (data.id) {
                this.isNew = false;
                this.getTicket(data.id);
            }

            this.getClients();
            this.getServiceTypes();
            this.getTicketPriorities();
            this.getTicketStatuses();
            this.getAgents();

            // Params for the right full height modal
            this.rightDialogConfig.disableClose = true;
            this.rightDialogConfig.autoFocus = true;
            // this.rightDialogConfig.width = '600px';
            this.rightDialogConfig.height = '2160px';
            this.rightDialogConfig.minWidth = '40%';
            this.rightDialogConfig.maxWidth = '50%';
            this.rightDialogConfig.position = {
              'top': '50px',
              'right': '0'
            };
    }

    @ViewChild('editor') editor: QuillEditorComponent;

    setControl() {
        this.form.setControl('editor', new FormControl('test - new Control'));
    }

    setFocus($event) {
        $event.focus();
    }

    ngOnInit() {
        this.form = this.fb.group({
            // description: [this.description, []],
            subject: ['', Validators.required],
            message: ['', Validators.required],
            dateOpen: dateOpen,
            dateClose: [],
            client: ['', Validators.required],
            queryUser: ['', Validators.required],
            agentUser: [],
            serviceType: [],
            inventory: [],
            ticketPriority: [],
            ticketStatus: ['', Validators.required],
            timeSpent: [],
            interventionNeeded: []
        });
        console.log(this.form);

        this.clientValue = this._clientStorage.get();

        if (this.clientValue == "undefined"){
            this.clientValue = false;
        }

        if (this.clientValue) {
            this.form.get('client').setValue(this.clientValue);
            this.onClientChange(this.clientValue);
        }
    }

    save() {
        this.dialogRef.close(this.form.value);
    }

    close() {
        this.dialogRef.close(false);
    }

    compareObjects(o1: any, o2: any): boolean {
        if (o1 !== null && o2 !== null) {
            return o1.id === o2.id;
        }
        return false;
    }

    onClientChange(event){
        this.form.value.queryUser = null;
        this.form.value.serviceType = null;
        this.getUserByClient(this.form.value.client.id);
    }

    onUserServiceChange(event){
        console.log(event);
        this.inventories = null;
        if (this.form.value.serviceType){
            this.getInventory(this.form.value.queryUser.id, this.form.value.serviceType);
        }
    }

    /************ Modals  ****************/

    openUserModal() {
        const dialogConfig = this.rightDialogConfig;

        dialogConfig.data = {
            user_id: null,
            title: 'NewUser'
        };

        const dialogRef = this.dialog.open(UserFormModalComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(
            data => {
                console.log("Debug:: Dialog output:", data);
                this.getUserByClient(this.form.value.client);
            }
        );
    }

    openClientModal() {
        const dialogConfig = this.rightDialogConfig;

        dialogConfig.data = {
            user_id: null,
            title: 'NewClient'
        };

        const dialogRef = this.dialog.open(ClientFormModalComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(
            data => {
                // console.log("Debug:: Dialog output:", data);
                this.getClients();
            }
        );
    }

    onSave() {
        this.dialogRef.close(this.form.value);
        if (this.isNew) {
            if (this.form.value) {
                if (this.form.value.serviceType) {
                    this.form.value.serviceType = this.form.value.serviceType.id;
                }
                if (this.form.value.agentUser) {
                    this.form.value.agentUser = this.form.value.agentUser.id;
                }
                this.form.value.queryUser = this.form.value.queryUser.id;
                this.saveTicket(this.form.value);
            }
        }else {
            if (this.form.value && this.ticket) {
                if (this.form.value.serviceType) {
                    this.form.value.serviceType = this.form.value.serviceType.id;
                }
                if (this.form.value.agentUser) {
                    this.form.value.agentUser = this.form.value.agentUser.id;
                }
                this.form.value.queryUser = this.form.value.queryUser.id;
                this.updateTicket(this.ticket.id, this.form.value);
            }
            // console.log('Debug:: Modifiy Ticket Save TODO');
        }
    }

    displayFn(client) {
        return client ? client.name : undefined;
    }

    displayAgent(agent) {
        return agent ? agent.firstName + ' ' + agent.lastName : undefined;
    }

    /************************* Filters **********************************/

    updateAgentUserFilter(event) {
      const val = event.target.value.toLowerCase();

      const filterA = this.agentUsersTemp.filter(function(d) {
        if (d !== undefined && d.firstName !== undefined && d.lastName !== undefined ) {
          var firstName = d.firstName.toLowerCase().indexOf(val) !== -1;
          var lastName  = d.lastName.toLowerCase().indexOf(val) !== -1;
          return firstName || lastName || !val;
        }
        return !val;
      });

      // update the rows
      this.agentUsers = filterA;
    }

    updateClientsFilter(event) {
      const val = event.target.value.toLowerCase();

      const filterA = this.clientsTemp.filter(function(d) {
        if (d !== undefined && d.name !== undefined ) {
          return d.name.toLowerCase().indexOf(val) !== -1 || !val;
        }
        return !val;
      });

      // update the rows
      this.clients = filterA;
    }

    updateClientUsersFilter(event) {
      const val = event.target.value.toLowerCase();

      const filterA = this.clientUsersTemp.filter(function(d) {
        if (d !== undefined && d.firstName !== undefined && d.lastName !== undefined ) {
          var firstName = d.firstName.toLowerCase().indexOf(val) !== -1;
          var lastName  = d.lastName.toLowerCase().indexOf(val) !== -1;
          return firstName || lastName || !val;
        }
        return !val;
      });

      // update the rows
      this.clientUsers = filterA;
    }

    /************ Getters ****************/

    getClients() {
        this._requestService.getList('Clients').subscribe(
            data => { 
                this.clients = data;
                this.clientsTemp = data;
            },
            err => console.error(err),
            () => console.log('Debug:: loading clients')
        );
    }

    getAgents() {
        this._requestService.getList('Agents').subscribe(
            data => { 
                this.agentUsers = data
                this.agentUsersTemp = data;
            },
            err => console.error(err),
            () => console.log('Debug:: loading agents')
        );
    }

    getUserByClient(company) {
        this._requestService.getObject('Clients', {'objectId': company+'/users'}).subscribe(
            data => { 
                this.clientUsers = data;
                this.clientUsersTemp = data;
            },
            err => {
                this.clientUsers = [];
                this.clientUsersTemp = [];
            },
            () => console.log('Debug:: loading client users')
        );
    }

    getServiceTypes() {
        this._requestService.getList('ServiceTypes').subscribe(
            data => { this.serviceTypes = data },
            err => console.error(err),
            () => console.log('Debug:: loading service types')
        );
    }

    getInventory(userId, serviceType) {
        this._requestService.getObject('Users', { 'objectId': userId+'/'+serviceType.name.toLowerCase()}).subscribe(
            data => { this.inventories = data },
            err => console.error(err),
            () => console.log('Debug:: loading inventories')
        );
    }

    getTicket(ticketId) {
        this._requestService.getObject('Tickets', {'objectId': ticketId}).subscribe(
            data => { 
                this.ticket = data;
                this.getUserByClient(data['queryUser'].company.id);
                this.getInventory(data['queryUser'].id, data['serviceType'])
            },
            err => console.error(err),
            () => console.log('Debug:: loading ticket id')
        );
    }

    getTicketPriorities() {
        this._requestService.getList('TicketPriorities').subscribe(
            data => { this.priorities = data },
            err => console.error(err),
            () => console.log('Debug:: loading ticket priorities')
        );
    }

    getTicketStatuses() {
        this._requestService.getList('TicketStatus').subscribe(
            data => { this.statuses = data },
            err => console.error(err),
            () => console.log('Debug:: loading ticket statuses')
        );
    }

    saveTicket(ticket) {
        this._requestService.postObject('Tickets', { 'object': ticket }).subscribe(
            data => { this.inventories = data },
            err => console.error(err),
            () => console.log('Debug:: saving tickets')
        );
    }

    updateTicket(ticketId, ticket) {
        this._requestService.patchObject('Tickets', { 'objectId': ticketId, 'object': ticket }).subscribe(
            data => { this.inventories = data },
            err => console.error(err),
            () => console.log('Debug:: updating ticket')
        );
    }
}
