import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../../environments/environment';
 
const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class ApiRequestService {

    constructor(private http:HttpClient) {}

    // Uses http.get() to load data from a single API endpoint
    getList(objectClass, queryString = []) {
        let url = environment.apiUrl + environment.apiFunctionPath[objectClass];
        let first = true;
        if (queryString.length) {
            console.log(queryString);
            for (let obj of queryString) {
                console.log(obj);
                url += first ? '?' : '&';
                url += obj.key + '=' + obj.val;
                first = false;
            }
        }
        return this.http.get(url);
    }

    getObject(objectClass, params) {
        return this.http.get(environment.apiUrl+environment.apiFunctionPath[objectClass]+'/'+params.objectId);
    }

    postObject(objectClass, params) {
        return this.http.post(environment.apiUrl+environment.apiFunctionPath[objectClass], params.object, httpOptions);
    }

    postSubObject(objectClass, subPath, params) {
        return this.http.post(environment.apiUrl+environment.apiFunctionPath[objectClass]+'/'+subPath, params.object, httpOptions);
    }

    patchObject(objectClass, params) {
        return this.http.patch(environment.apiUrl+environment.apiFunctionPath[objectClass]+'/'+params.objectId, params.object, httpOptions);
    }

    putObject(objectClass, params) {
        return this.http.put(environment.apiUrl+environment.apiFunctionPath[objectClass]+'/'+params.objectId, params.object, httpOptions);
    }

    deleteObject(objectClass, params) {
        return this.http.delete(environment.apiUrl+environment.apiFunctionPath[objectClass]+'/'+params.objectId);
    }
}
