import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { environment } from '../../../../environments/environment';
import { ApiRequestService } from '../../../core/api/apiRequest.service';

@Component({
    selector: 'app-user-show',
    templateUrl: './userShow.component.html',
    styleUrls: ['./userShow.component.scss']
})
export class UserShowComponent implements OnInit {

    private title: string;
    private user;

    constructor(
        private dialogRef: MatDialogRef<UserShowComponent>,
        private _requestService: ApiRequestService,
        @Inject(MAT_DIALOG_DATA) data) {

            this.title = data.title;
            if(data.obj) {
                this.user = data.obj;
            }
            else {
                // query user id
                this.getUser(data.id);
            }
    }

    getUser(user_id) {
        this._requestService.getObject('Users', {'objectId': user_id}).subscribe(
            data => { this.user = data },
            err => console.error(err),
            () => console.log('Debug:: loading user')
        );
    }

    ngOnInit() {}

    close() {
        this.dialogRef.close();
    }
}
