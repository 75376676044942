import { Injectable } from '@angular/core'

const TOKEN_KEY = 'ClientID';

@Injectable()
export class ClientStorage {

  constructor() { }

  signOut() {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.clear();
  }

  public save(data) {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, data);
  }

  public get() {
    return sessionStorage.getItem(TOKEN_KEY);
  }
}