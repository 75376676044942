import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { ApiRequestService } from '../../../core/api/apiRequest.service';

import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-client-show',
    templateUrl: './clientShow.component.html',
    styleUrls: ['./clientShow.component.scss']
})
export class ClientShowComponent implements OnInit {

    private title: string;
    private client;

    constructor(
        private dialogRef: MatDialogRef<ClientShowComponent>,
        private _clientService: ApiRequestService,
        @Inject(MAT_DIALOG_DATA) data) {

            this.title = data.title;
            if(data.obj) {
                this.client = data.obj;
            }
            else {
                // query user id
                this.getClient(data.id);
            }
    }

    getClient(userId) {
        this._clientService.getObject('Clients', { 'objectId': userId }).subscribe(
            data => {
                this.client = data;
            }
        );
    }


    ngOnInit() {}

    close() {
        this.dialogRef.close();
    }
}
