import { Injectable } from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
  icon?: string;
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
}

const MENUITEMS = [
  // {
  //   state: '/',
  //   name: 'HOME',
  //   type: 'link',
  //   icon: 'dashboard'
  // },
  {
    state: 'tickets',
    name: 'Tickets',
    type: 'link',
    icon: 'local_offer'
  },
  {
    state: 'inventories',
    name: 'Inventories',
    type: 'sub',
    icon: 'devices',
    children: [
      {state: 'hardwares', name: 'Hardwares', icon: 'computer'} as ChildrenItems,
      // {state: 'hardwareModels', name: 'HardwareModels', icon: 'business'} as ChildrenItems,
      // {state: 'hardwareStatus', name: 'HardwareStatus', icon: 'business'} as ChildrenItems,
      // {state: 'hardwareTypes', name: 'HardwareTypes', icon: 'business'} as ChildrenItems,
      // {state: 'manufacturers', name: 'Manufacturers', icon: 'business'} as ChildrenItems,
      {state: 'softwares', name: 'Softwares', icon: 'business'} as ChildrenItems,
      // {state: 'softwareModels', name: 'SoftwareModels', icon: 'business'} as ChildrenItems,
      // {state: 'softwareStatus', name: 'SoftwareStatus', icon: 'business'} as ChildrenItems,
      // {state: 'softwareTypes', name: 'SoftwareTypes', icon: 'business'} as ChildrenItems,
      // {state: 'editors', name: 'Editors', icon: 'business'} as ChildrenItems
    ]
  } as Menu,
  {
    state: 'admin',
    name: 'Admin',
    type: 'sub',
    icon: 'settings',
    children: [
      {state: 'users', name: 'Users', icon: 'supervised_user_circle'} as ChildrenItems,
      {state: 'clients', name: 'Clients', icon: 'supervised_user_circle'} as ChildrenItems
      // {state: 'hardwareModels', name: 'HardwareModels', icon: 'business'},
      // {state: 'hardwareStatus', name: 'HardwareStatus', icon: 'business'},
      // {state: 'hardwareTypes', name: 'HardwareTypes', icon: 'business'},
      // {state: 'manufacturers', name: 'Manufacturers', icon: 'business'},
      // {state: 'softwares', name: 'Softwares', icon: 'business'},
    ]
  } as Menu,
  // {
  //   state: 'http://primer.nyasha.me/docs',
  //   name: 'DOCS',
  //   type: 'extTabLink',
  //   icon: 'local_library'
  // }
];

@Injectable()
export class MenuService {
  getAll(): Menu[] {
    return MENUITEMS;
  }

  add(menu: Menu) {
    MENUITEMS.push(menu);
  }
}
