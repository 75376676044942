import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { LoadingBarRouterModule } from '@ngx-loading-bar/router';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { Interceptor } from './app.interceptor';
import { ClientStorage, TokenStorage, UserStorage } from './core/_storage';

import { AuthGuard } from './core/_guards/auth.guard';
import { AlertComponent } from './core/_directives/alert.component';
import { AlertService } from './core/_services/alert.service';
import { AuthService } from './core/_services/auth.service';

import { AgmCoreModule } from '@agm/core';

import { ApiRequestService } from './core/api/apiRequest.service';

import { QuillModule } from 'ngx-quill';


import { OpenTicketModalComponent } from './tickets/ticketModals/openTicketModal.component';
import { DeleteDialogComponent } from './core/dialogs/deleteDialog.component';
// import { OpenHardwareModalComponent } from './inventories/hardwares/hardwareModals/openHardwareModal.component';
import { ShowHardwareModalComponent } from './inventories/hardwares/hardwareModals/showHardwareModal.component';
import { ShowSoftwareModalComponent } from './inventories/softwares/softwareModals/showSoftwareModal.component';

import { UserFormModalComponent } from './admin/users/userModals/userFormModal.component';
import { UserShowComponent } from './admin/users/userModals/userShow.component';

import { ClientFormModalComponent } from './admin/clients/clientModals/clientFormModal.component';
import { ClientShowComponent } from './admin/clients/clientModals/clientShow.component';

import {
  MatSidenavModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatCardModule,
  MatMenuModule,
  MatCheckboxModule,
  MatInputModule,
  MatIconModule,
  MatButtonModule,
  MatToolbarModule,
  MatTabsModule,
  MatListModule,
  MatSlideToggleModule,
  MatSelectModule,
  MatProgressBarModule,
  MatAutocompleteModule,
  MatSnackBarModule,
  MatDialogModule } from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BidiModule } from '@angular/cdk/bidi';

import {
  MenuComponent,
  HeaderComponent,
  SidebarComponent,
  NotificationComponent,
  OptionsComponent,
  AdminLayoutComponent,
  AuthLayoutComponent,
  AccordionAnchorDirective,
  AccordionLinkDirective,
  AccordionDirective} from './core';

import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true,
  minScrollbarLength: 20
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    NotificationComponent,
    OptionsComponent,
    MenuComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    AlertComponent,
    UserFormModalComponent,
    ClientFormModalComponent,
    OpenTicketModalComponent,
    DeleteDialogComponent,
    ShowHardwareModalComponent,
    ShowSoftwareModalComponent,
    UserShowComponent,
    ClientShowComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(AppRoutes),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    LoadingBarRouterModule,
    MatSidenavModule,
    MatCardModule,
    MatMenuModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatToolbarModule,
    MatTabsModule,
    MatListModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatProgressBarModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatSnackBarModule,
    FlexLayoutModule,
    BidiModule,
    AgmCoreModule.forRoot({apiKey: 'AIzaSyB3HQ_Gk_XRt6KitPdiHQNGpVn0NDwQGMI'}),
    PerfectScrollbarModule,
    QuillModule,
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    { 
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true
    },
    ClientStorage,
    TokenStorage,
    UserStorage,
    AuthGuard,
    AlertService,
    ApiRequestService,
    AuthService,
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    UserFormModalComponent,
    ClientFormModalComponent,
    OpenTicketModalComponent,
    DeleteDialogComponent,
    ShowHardwareModalComponent,
    ShowSoftwareModalComponent,
    UserShowComponent,
    ClientShowComponent,
  ]
})
export class AppModule { }
