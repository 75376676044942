import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { catchError, map, tap, startWith, switchMap, debounceTime, distinctUntilChanged, takeWhile, first } from 'rxjs/operators';
import { ApiRequestService } from '../api/apiRequest.service';
import { ClientStorage, UserStorage, TokenStorage } from '../_storage';
import { MatDialog, MatDialogConfig } from "@angular/material";
import { UserFormModalComponent } from '../../admin/users/userModals/userFormModal.component';
import { ClientFormModalComponent } from '../../admin/clients/clientModals/clientFormModal.component';
import { OpenTicketModalComponent } from '../../tickets/ticketModals/openTicketModal.component';

import * as screenfull from 'screenfull';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  private clients;
  private selected;
  private temp;
  private clientVal;
  private user;

  private clientCtrl: FormControl;

  private companyList: Observable<any[]>;
  private rightDialogConfig = new MatDialogConfig();

  @Output() toggleSidenav = new EventEmitter<void>();
  @Output() toggleNotificationSidenav = new EventEmitter<void>();

  constructor(
    private _requestService: ApiRequestService, 
    private _clientStorage: ClientStorage,
    private _tokenStorage: TokenStorage,
    private _user: UserStorage,
    private dialog: MatDialog,
    private router: Router) {
    // this.getClients();

    this.clientCtrl = new FormControl();
    this.user = _user.getUser();

    // this.companyList = this.clientCtrl.valueChanges
    //   .pipe(
    //       startWith(''),
    //       map(state => state ? this.filterClient(state) : this.temp.slice())
    //   );
  }

  // filterClient(name: string) {
  //   return this.temp.filter(function(de) {
  //     // obj !== undefined && typeof(obj) === 'number' && !isNaN(obj);
  //     if (de !== undefined && de.name !== undefined) {
  //         return de.name.toLowerCase().indexOf(name.toLowerCase()) === 0;
  //     }
  //     return false;
  //   })
  // }

  // updateClientFilter(event) {
  //   const val = event.target.value.toLowerCase();

  //   // filter our data
  //   const temp = this.temp.filter(function(d) {
  //     if (d !== undefined && d.name !== undefined) {
  //         return d.name.toLowerCase().indexOf(val) !== -1 || !val;
  //     }
  //     return !val;
  //   });

  //   // const temp = this.temp.filter(company => company.name.toLowerCase().indexOf(val) !== -1 || !val);

  //   // update the rows
  //   this.clients = temp;
  //   // Whenever the filter changes, always go back to the first page
  //   // this.table.offset = 0;
  // }

  onClientChange(event){
    // console.log(event.data);
    console.log(this.selected);
    this._clientStorage.save(this.selected);
  }

  fullScreenToggle(): void {
    if (screenfull.enabled) {
      screenfull.toggle();
    }
  }

  getClients() {
    this._requestService.getList('Clients').subscribe(
      data => { 
        this.clients = data, 
        this.temp = data
      },
      err => console.error(err),
      () => console.log('Debug:: loading clients')
    );
  }

  /************************ User butons ********************/

  logout() {
    this._clientStorage.signOut();
    this._tokenStorage.signOut();
    this.router.navigate(['session/signin']);
  }

  /************************ Modals *************************/

  openUserModal() {
    var dialogConfig = this.rightDialogConfig;

    dialogConfig.data = {
        user_id: null,
        title: 'NewUser'
    };

    this.dialog.open(UserFormModalComponent, dialogConfig);
  }

  openClientModal() {
    var dialogConfig = this.rightDialogConfig;

    dialogConfig.data = {
        user_id: null,
        title: 'NewClient'
    };

    this.dialog.open(ClientFormModalComponent, dialogConfig);
  }

  openTicketModal() {
    var dialogConfig = this.rightDialogConfig;

    dialogConfig.data = {
        user_id: null,
        title: 'NewTicket'
    };

    this.dialog.open(OpenTicketModalComponent, dialogConfig);
  }

  compareObjects(o1: any, o2: any): boolean {
    if (o1 !== null && o2 !== null) {
      return o1.id === o2.id;
    }
    return false;
  }

  /********************************************/
  filteredOptions;

  ngOnInit() {

    // Params for the right full height modal
    this.rightDialogConfig.disableClose = true;
    this.rightDialogConfig.autoFocus = true;
    // this.rightDialogConfig.width = '600px';
    this.rightDialogConfig.height = '2160px';
    this.rightDialogConfig.maxWidth = '50%';
    this.rightDialogConfig.position = {
      'top': '50px',
      'right': '0'
    };

    this.getClients();
    this.selected = this._clientStorage.get();
    this.clientCtrl.setValue(this.selected);
    console.debug(this.selected);



    // this.filteredOptions = this.clientCtrl.valueChanges
    //   .pipe(
    //     startWith(null),
    //     debounceTime(200),
    //     distinctUntilChanged(),
    //     switchMap(val => {
    //       return this.filter(val || '')
    //     })       
    //   );
  }

  // filter(val: string) {
  //   return this._requestService.getList('Clients')
  //   .pipe(
  //     map(response => response.filter(option =>
  //       option.name.toLowerCase().indexOf(val.toLowerCase())
  //     ))
  //   );
  // }

}
